import { Checkout } from '@bp/frontend/domains/checkout/models';

import { composeEntitiesInMemoryPagedListSelectors } from '@bp/admins-shared/features/entity';

import { checkoutsFeature } from '../checkouts.store-feature';

import { IState } from './checkouts.feature';

export const CHECKOUTS_LIST_SELECTORS = composeEntitiesInMemoryPagedListSelectors<Checkout, IState>(
	checkoutsFeature.selectList,
);

import { Checkout } from '@bp/frontend/domains/checkout/models';

import { composeEntitiesListActions, EntitiesListActions } from '@bp/admins-shared/features/entity';

import { CheckoutsQueryParams } from '../../models';

import { ACTIONS_SCOPE, CHECKOUTS_LIST_API_ACTIONS } from './checkouts-api.actions';

export const CHECKOUTS_LIST_ACTIONS: EntitiesListActions<Checkout, CheckoutsQueryParams> = {
	...composeEntitiesListActions(ACTIONS_SCOPE),

	api: CHECKOUTS_LIST_API_ACTIONS,
};

import { createReducer, createFeature } from '@ngrx/store';

import { Checkout } from '@bp/frontend/domains/checkout/models';

import {
	composeEntitiesInMemoryPagedListReducer,
	ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE,
	EntitiesInMemoryPagedListState
} from '@bp/admins-shared/features/entity';

import { CHECKOUTS_LIST_ACTIONS } from './checkouts.actions';

export interface IState extends EntitiesInMemoryPagedListState<Checkout> {}

export const initialState: IState = {
	...ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE,
};

export const checkoutsListFeature = createFeature({
	name: 'list',
	reducer: createReducer(
		initialState,
		...composeEntitiesInMemoryPagedListReducer(initialState, CHECKOUTS_LIST_ACTIONS),
	),
});

import { IPageQueryParamsDTO } from '@bp/shared/models/common';
import { DTO, MetadataQueryParamsBase, mixinMetadataPageQueryParams } from '@bp/shared/models/metadata';

type CheckoutsQueryParamsDTO = DTO<CheckoutsQueryParams> & IPageQueryParamsDTO;

export class CheckoutsQueryParams extends mixinMetadataPageQueryParams(MetadataQueryParamsBase) {

	static {
		this._initClassMetadata();
	}

	constructor(dto?: CheckoutsQueryParamsDTO) {
		super(dto);
	}

}

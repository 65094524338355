import { createFeature, combineReducers } from '@ngrx/store';

import { checkoutsListFeature } from './list/checkouts.feature';

const reducer = combineReducers({
	[checkoutsListFeature.name]: checkoutsListFeature.reducer,
});

export const checkoutsFeature = createFeature({
	name: '[domain]:checkouts',
	reducer,
});
